<template>
  <div class="w-100">
    <!-- Desktop -->
    <div class="d-none d-sm-block">
      <table :class="{ 'table-closed': !isOpen }" class="table border-left border-right border-bottom">
        <thead class="thead-light">
          <tr>
            <th class="text-center">Cant.</th>
            <th>Código</th>
            <th>Descripción</th>
            <th v-if="!isDistribution" class="text-center">Impuesto</th>
            <th class="text-right">Precio</th>
            <th class="text-right">Subtotal</th>
            <th v-if="isOpen"></th>
          </tr>
        </thead>
        <tbody ref="itemContainer">
          <tr v-for="quoteItem in quoteItems" :key="quoteItem.id" :id="'item-' + quoteItem.id" :class="{'sortable-row': isOpen }" style="height: 56px">
            <!-- Quantity -->
            <td v-if="quoteItem.line_type == 'item'" class="text-center align-middle" style="min-width: 100px; width: 100px">
              <QuoteItemQuantityInput
                v-if="!quoteItem.is_labor && quote.is_open"
                :quote-id="quote.id"
                :quote-item="quoteItem"
                @item-updated="updateItem"
                @total-changed="$emit('total-changed')"
              />
              <span v-else>{{ quoteItem.quantity }}</span>
            </td>
            <td v-else class="text-center font-weight-bold align-middle" style="min-width: 100px; width: 100px"> - </td>

            <!-- Item Code -->
            <td v-if="quoteItem.product" class="align-middle px-1" style="max-width: 200px">
              <b-link v-if="isOpen" @click="openModal(quoteItem)">
                {{ quoteItem.product.item_code }}
                <span v-if="!quoteItem.product.is_valid" class="text-danger">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                </span>
                <span v-else-if="itemProfitability(quoteItem)" class="text-danger">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                </span>
                <span v-else-if="quoteItem.quantity > quoteItem.product.stock && isStockEligible(quoteItem)" class="text-warning">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                </span>
              </b-link>
              <span v-else>{{ quoteItem.product.item_code }}</span>
            </td>
            <td v-else-if="quoteItem.is_labor" class="align-middle" style="min-width: 120px">Mano de Obra</td>
            <td v-else-if="quoteItem.line_type == 'text'" class="align-middle" style="min-width: 120px" colspan="5">
              <b-link v-if="isOpen" @click="openLineText(quoteItem)" class="font-weight-bold text-secondary">
                <span v-if="quoteItem.line_text">{{ quoteItem.line_text }}</span>
                <span v-else>Click para agregar título</span>
              </b-link>
              <div v-else class="font-weight-bold text-secondary">
                <span v-if="quoteItem.line_text">{{ quoteItem.line_text }}</span>
                <span v-else>Click para agregar título</span>
              </div>
            </td>
            <td v-else style="min-width: 120px"></td>

            <!-- Description -->
            <td v-if="quoteItem.product" :class="{ grabbable: isOpen }" class="align-middle td-truncate d-none d-sm-table-cell px-1" style="max-width: 250px">{{ quoteItem.product.description }}</td>
            <td v-else-if="quoteItem.is_labor" :class="{ grabbable: isOpen }" class="align-middle td-truncate d-none d-sm-table-cell" style="max-width: 220px">{{ quoteItem.labor_description }}</td>

            <!-- Impuesto -->
            <td v-if="quoteItem.product && !isDistribution" :class="{ grabbable: isOpen }" class="text-center align-middle px-1">
              <b-dropdown
                id="taxCodeItem"
                ref="taxCode"
                size="sm"
                variant="white"
                :text="quoteItem.tax_code"
                style="width: 70px;"
                :disabled="!isOpen"
              >
                <b-dropdown-item-button
                  v-for="tax in itemTaxCodes" :key="tax.value"
                  @click="updateTaxCode(quoteItem, tax.value)"
                  :active="quoteItem.tax_code == tax.value"
                  class="p-0 m-0"
                >
                  <small>{{tax.text}}</small>
                </b-dropdown-item-button>
              </b-dropdown>
            </td>

            <!-- Price -->
            <td v-if="quoteItem.product" :class="{ grabbable: isOpen }" class="text-right align-middle price-display px-1" style="width: 5rem">
              <span v-if="displayCRC">{{ listedPrice(quoteItem) | colones }}</span>
              <span v-else>{{ listedPrice(quoteItem) | dollarize }}</span>
            </td>

            <!-- Subtotal -->
            <td v-if="quoteItem.product" :class="{ grabbable: isOpen, 'text-danger': quoteItem.quantity < 0 }" class="text-right align-middle price-display px-1" style="width: 5rem">
              <span v-if="displayCRC">{{ quoteItem.quantity * listedPrice(quoteItem) | colones }}</span>
              <span v-else>{{ quoteItem.quantity * listedPrice(quoteItem) | dollarize }}</span>
            </td>
            <td v-else-if="quoteItem.is_labor" :class="{ grabbable: isOpen }" class="text-right align-middle price-display">
              <span v-if="displayCRC">{{ quoteItem.custom_colones | colones }}</span>
              <span v-else>{{ quoteItem.custom_dollars | dollarize }}</span>
            </td>

            <!-- Delete Icon -->
            <td v-if="isOpen" class="text-right align-middle" style="width: 50px">
              <span v-if="quoteItems.length == 1"></span>
              <b-link v-else class="delete-icon" @click="deleteItem(quoteItem.id)">
                <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width />
              </b-link>
            </td>
          </tr>
          <QuoteItemQuickAddRow v-if="!isDistribution && isOpen" :quote="quote" :quote-items="quoteItems" @quote-item-added="addQuoteItem" />
        </tbody>
      </table>
    </div>

    <!-- Mobile -->
    <div class="d-sm-none">
      <table :class="{ 'table-closed': !isOpen }" class="table table-sm border-left border-right border-bottom">
        <thead class="thead-light">
          <tr>
            <th class="text-center" style="width: 50px">Cant.</th>
            <th>Código</th>
            <th v-if="!isDistribution" class="text-center">Impuesto</th>
            <th class="text-right">Subtotal</th>
            <th v-if="isOpen"></th>
          </tr>
        </thead>
        <tbody>
          <!-- Desktop -->
          <tr v-for="quoteItem in quoteItems" :key="quoteItem.id" :id="'item-' + quoteItem.id" style="height: 50px">
            <!-- Quantity -->
            <td v-if="quoteItem.product" class="text-center align-middle">{{ quoteItem.quantity }}</td>
            <td v-else class="text-center font-weight-bold align-middle"> - </td>

            <!-- Item Code -->
            <td v-if="quoteItem.product" class="align-middle" style="min-width: 120px">
              <b-link v-if="isOpen" @click="openModal(quoteItem)">
                {{ quoteItem.product.item_code }}
                <span v-if="!quoteItem.product.is_valid" class="text-danger">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                </span>
                <span v-else-if="quoteItem.quantity > quoteItem.product.stock && isStockEligible(quoteItem)" class="text-warning">
                  <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                </span>
              </b-link>
              <span v-else>{{ quoteItem.product.item_code }}</span>
            </td>
            <td v-else-if="quoteItem.line_type == 'text'" class="text-center align-middle" style="min-width: 120px" colspan="2">
              <b-link v-if="isOpen" @click="openLineText(quoteItem)"  class="font-weight-bold text-secondary">
                <span v-if="quoteItem.line_text">{{ quoteItem.line_text }}</span>
                <span v-else>Click para agregar título</span>
              </b-link>
              <div v-else class="font-weight-bold text-secondary">
                <span v-if="quoteItem.line_text">{{ quoteItem.line_text }}</span>
                <span v-else>Click para agregar título</span>
              </div>
            </td>
            <td v-else style="min-width: 120px"></td>

            <!-- Impuesto -->
            <td v-if="quoteItem.product && !isDistribution" class="text-center align-middle px-1">
              <b-dropdown
                id="taxCodeItem"
                ref="taxCode"
                size="sm"
                variant="white"
                :text="quoteItem.tax_code"
                :disabled="!isOpen"
                style="width: 70px;"
              >
                <b-dropdown-item-button
                  v-for="tax in itemTaxCodes" :key="tax.value"
                  @click="updateTaxCode(quoteItem, tax.value)"
                  :active="quoteItem.tax_code == tax.value"
                  class="p-0 m-0"
                >
                  <small>{{tax.text}}</small>
                </b-dropdown-item-button>
              </b-dropdown>
            </td>

            <!-- Subtotal -->
            <td v-if="quoteItem.product" class="text-right grabbable align-middle price-display"  :class="quoteItem.quantity < 0 ? 'text-danger' : null">
              <span v-if="displayCRC">{{ quoteItem.quantity * listedPrice(quoteItem) | colones }}</span>
              <span v-else>{{ quoteItem.quantity * listedPrice(quoteItem) | dollarize }}</span>
            </td>
            <td v-else></td>

            <!-- Delete Icon -->
            <td v-if="isOpen" class="text-right align-middle" style="width: 56px">
              <span v-if="quoteItems.length == 1"></span>
              <b-link v-else class="delete-icon" @click="deleteItem(quoteItem.id)">
                <font-awesome-icon :icon="['far', 'times']" size="lg" fixed-width />
              </b-link>
            </td>
          </tr>
          <tr v-if="!isDistribution">
            <td colspan="5">
              <QuoteItemAddLineText
                v-if="isOpen"
                :quote-id="quote.id"
                @quote-item-added="addQuoteItem"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Item Detail Modal -->
    <b-modal v-model="modalShow" static centered scrollable hide-footer @hide="clearSelectedItem">
      <template #modal-title>
        <h5 v-if="selectedQuoteItem">{{ selectedQuoteItem.product.item_code }}</h5>
      </template>
      <!-- Alerts -->
      <b-container v-if="selectedQuoteItem">
        <b-row v-if="!selectedQuoteItem.product.is_valid" align-h="center" class="text-danger mb-3">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          <small class="ml-2">Este artículo está marcado como inactivo en el inventario!</small>
        </b-row>
        <b-row v-if="selectedQuoteItem.quantity > selectedQuoteItem.product.stock && isStockEligible(selectedQuoteItem)" align-h="center" class="text-warning mb-2">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          <small v-if="selectedQuoteItem.product.stock === 0" class="ml-2">Este artículo está fuera de stock</small>
          <small v-else class="ml-2">La cantidad deseada excede el stock disponible</small>
        </b-row>
        <b-row v-if="itemProfitability(selectedQuoteItem)" align-h="center" class="text-danger mb-2">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          <small class="ml-2">Verifique el precio de este artículo con el Líder de producto respectivo</small>
        </b-row>
      </b-container>
      <section>
        <b-row v-if="selectedQuoteItem" align-h="center" class="fs-14">
          <b-col sm="11">
            <article id="item-quantity">
              <b-row><b-col><p class="font-lato mb-1">CANTIDAD</p></b-col></b-row>
              <b-row align-h="center" class="mb-2">
                <b-col>
                  <ValidationProvider rules="required|min_value:0.01" name="cantidad" tag="div" v-slot="{ errors }">
                    <b-row align-h="center">
                      <b-input-group size="sm" style="width: 130px">
                        <b-input-group-prepend>
                          <b-button :disabled="errors.length > 0 || form.quantity <= 1 || updatingQuantity" @click="decrement">
                            <font-awesome-icon :icon="['fas', 'minus']" size="lg" fixed-width />
                          </b-button>
                        </b-input-group-prepend>
                        <b-input
                          v-model="form.quantity"
                          ref="quantityInput"
                          :class="{ 'red-input-border': errors[0] }"
                          class="text-center"
                          @click="$nextTick(() => $refs.quantityInput.select())"
                          @blur="errors[0] ? null : updateQuantity()"
                          @keyup.enter="errors[0] ? null : updateQuantity()"
                        />
                        <b-input-group-append>
                          <b-button :disabled="errors.length > 0 || updatingQuantity" @click="increment">
                            <font-awesome-icon :icon="['fas', 'plus']" size="lg" fixed-width />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-row>
                    <b-row v-if="errors.length > 0" align-h="center" class="mt-1" style="height: 20px">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-row>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </article>
            <hr class="m-1">
            <article id="item-price">
              <b-row><b-col><p class="font-lato mb-1">PRECIO</p></b-col></b-row>
              <b-row>
                <b-col>
                  <b-table-simple borderless>
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-right text-muted" style="width: 100px">
                          <span>{{ priceList | titleize }}:</span>
                        </b-td>
                        <b-td style="width: 120px" :class="{'line-through': selectedQuoteItem.is_custom && !editingCustomPrice }" class="price-display">
                          <span v-if="displayCRC">{{ defaultListedPrice(selectedQuoteItem) | colones }}</span>
                          <span v-else>{{ defaultListedPrice(selectedQuoteItem) | dollarize }}</span>
                        </b-td>
                        <b-td>
                          <div v-if="isCustomPriceEligible(selectedQuoteItem)">
                            <b-checkbox v-model="form.is_custom" switch @change="toggleCustom">Editar</b-checkbox>
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr v-show="form.is_custom || editingCustomPrice">
                        <b-td class="text-right text-muted align-middle">Custom:</b-td>
                        <b-td colspan="2" class="align-middle">
                          <div v-show="editingCustomPrice">
                            <ValidationProvider :rules="{ required: true, min_value: minPriceOfSelectedItemAllowed }" immediate tag="div" v-slot="{ errors }">
                              <b-input-group size="sm" style="width: 200px">
                                <b-input-group-prepend is-text>
                                  <span v-if="displayCRC">₡</span>
                                  <span v-else>$</span>
                                </b-input-group-prepend>
                                <b-input
                                  v-model="customPriceForm.customPrice"
                                  ref="customPriceInput"
                                  autofocus
                                  :class="{ 'red-input-border': errors[0] }"
                                  @keyup.enter="errors[0] ? null : updateCustomPrice()"
                                />
                                <b-input-group-append>
                                  <b-button :disabled="errors.length > 0" variant="success" size="sm" @click="updateCustomPrice()">
                                    <font-awesome-icon :icon="['far', 'save']" fixed-width class="mx-1" />
                                  </b-button>
                                </b-input-group-append>
                                <b-input-group-append>
                                  <b-button size="sm" variant="danger" @click="cancelCustomEditing()">
                                    <font-awesome-icon :icon="['far', 'times']" fixed-width class="text-light mx-1" />
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </ValidationProvider>
                          </div>
                          <div v-if="!editingCustomPrice" class="price-display">
                            <div class="click-to-edit">
                              <div v-if="isCustomPriceEligible(selectedQuoteItem)"  @click="focusCustomPrice">
                                <span v-if="displayCRC">{{ selectedQuoteItem.custom_colones | colones2 }}</span>
                                <span v-else>{{ selectedQuoteItem.custom_dollars | dollarize }}</span>
                              </div>
                              <div
                                v-else
                                style="cursor:not-allowed; hover"
                                v-b-tooltip.hover.v-dark.bottom='"No permitido: Contacte al líder de producto"'
                              >
                                <span v-if="displayCRC">{{ selectedQuoteItem.custom_colones | colones2 }}</span>
                                <span v-else>{{ selectedQuoteItem.custom_dollars | dollarize }}</span>
                              </div>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </article>
            <hr class="m-1">
            <article id="item-stock" v-if="isStockEligible(selectedQuoteItem)" class="mt-3 px-0">
              <b-row class="mb-2">
                <b-col cols="2"><span class="font-lato mb-1">STOCK</span></b-col>
                <b-col class="text-right">
                  <WarehouseDataWidget :item="selectedQuoteItem.product" :is-distribution="isDistributor" :is-installation="isInstaller" @item-stock-updated="updateItemStock" />
                  <PurchaseOrderDataWidget v-if="!isDistributor && !isInstaller" :item="selectedQuoteItem.product" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table-simple borderless>
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-right text-muted" style="width: 150px">Bodega principal:</b-td>
                        <b-td style="width: 120px">
                          <span v-if="isInstaller && selectedQuoteItem.product.stock > 30 ">30</span>
                          <span v-else>{{selectedQuoteItem.product.stock}}</span>
                        </b-td>
                        <b-td></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <hr class="m-1">
            </article>
            <article id="item-cabys-code" class="px-0">
              <b-row class="mb-2">
                <b-col><span class="font-lato mb-1">CÓDIGO CABYS</span></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col class="text-center">
                  <p v-if="selectedQuoteItem.product.sap_cabys_code">{{ selectedQuoteItem.product.sap_cabys_code }}</p>
                  <div v-else class="text-muted">
                    <span>No cuenta con Cabys</span>
                    <font-awesome-icon :icon="['far', 'info-circle']" size="sm"/>
                  </div>
                </b-col>
              </b-row>
            </article>
            <hr class="m-1">
            <article id="item-type-name" class="px-0">
              <b-row class="mb-2">
                <b-col><span class="font-lato mb-1">TIPO DE PRODUCTO</span></b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col class="text-center">
                  <p v-if="selectedQuoteItem.product.type_name">{{ selectedQuoteItem.product.type_name }}</p>
                  <p v-else class="text-muted">-</p>
                </b-col>
              </b-row>
            </article>
            <hr class="m-1">
            <article id="item-description">
              <b-row>
                <b-col>
                  <p class="font-lato">DESCRIPCIÓN</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p>{{ selectedQuoteItem.product.description_lg }}</p>
                </b-col>
              </b-row>
            </article>
          </b-col>
        </b-row>
      </section>
    </b-modal>

    <b-modal
      v-model="editLineTextModalShow"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      trim
    >
      <b-row v-if="selectedItemLineText" class="pb-2">
        <b-col>
          <b-form-textarea
            id="text-line"
            v-model="lineTextForm.line_text"
            rows="3"
            autofocus
            :state="!lineTextForm.line_text || lineTextForm.line_text.length <= 50"
            max-rows="6"
          ></b-form-textarea>
          <p v-if="lineTextForm.line_text && lineTextForm.line_text.length > 50" class="text-danger">
            El título debe ser menor o igual a 50 caracteres
          </p>
        </b-col>
      </b-row>
      <b-row v-if="selectedItemLineText" class="float-right">
        <b-col>
          <b-button v-if="lineTextForm.line_text" variant="success" :disabled="lineTextForm.line_text.length > 50" size="sm" @click="updateLineText">Guardar</b-button>
          <b-button v-else variant="success" size="sm" @click="updateLineText">Guardar</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { Sortable } from '@shopify/draggable'
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate'
import QuoteItemQuantityInput from './QuoteItemQuantityInput'
import QuoteItemQuickAddRow from './QuoteItemQuickAddRow'
import QuoteItemAddLineText from './QuoteItemAddLineText'
import WarehouseDataWidget from '../shared/WarehouseDataWidget'
import PurchaseOrderDataWidget from '../shared/PurchaseOrderDataWidget'
import { taxCodes } from '../../sharedOptions/taxCodes'

export default {
  name: 'QuoteItemTable',
  components: {
    ValidationProvider,
    QuoteItemQuantityInput,
    QuoteItemQuickAddRow,
    QuoteItemAddLineText,
    WarehouseDataWidget,
    PurchaseOrderDataWidget
  },
  props: {
    quote: {
      type: Object,
      required: false
    },
    quoteItems: {
      type: Array,
      required: true
    },
    priceList: {
      type: String,
      required: true
    },
    isDistribution: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        quantity: null,
        is_custom: false
      },
      customPriceForm: {
        customPrice: 0
      },
      modalShow: false,
      editLineTextModalShow: false,
      lineTextForm:{
        line_text: null
      },
      itemCodesEligibles: ['INS-', 'SERVICIO', 'SM-', 'SRVTA-', 'TRANSPORTE', 'ENCOM', 'FREIGHT', 'HORATECNICO', 'HORAYUDANT', 'PROGRAMA'],
      selectedItemLineText: null,
      selectedQuoteItem: null,
      minPriceOfSelectedItemAllowed: 0.01, // minimum custom price
      processing: false,
      updatingQuantity: false,
      editingCustomPrice: false,
      itemTaxCodes: taxCodes
    }
  },
  computed: {
    ...mapGetters(['isInstaller', 'isDistributor', 'isProductLeader', 'isAdmin']),
    displayCRC () { return this.$store.state.displayCRC },
    exchangeRate () { return this.quote.usd_crc_rate },
    isOpen () { return this.quote ? this.quote.is_open : true },
    scopedUrlString () { return this.isDistribution ? 'contractors' : 'production' },
    scopedUrlDomain () { return this.isDistribution ? 'dist' : 'prod' },
  },
  mounted () {
    const sortable = new Sortable(this.$refs.itemContainer, {
      draggable: '.sortable-row',
      delay: 300,
      scrollable: { speed: 0 },
      mirror: { constrainDimensions: true }
    })
    sortable.on('sortable:stop', () => {
      console.log('sortable:stop');
      const items = sortable.getDraggableElementsForContainer(this.$refs.itemContainer)
      const data = items.map(item => ({ "quote_item_id": item.id.split('-')[1] }));
      this.$http.patch(`/api/production/quotes/${this.quote.id}/quote_items/sort`, {
        quote_items: data
      })
      // Force sync to SAP when items are reordered
      this.$emit('sync-to-sap')
      this.$emit('total-changed')
    })
  },
  methods: {
    openModal (quoteItem) {
      this.selectedQuoteItem = quoteItem;
      this.form.quantity = quoteItem.quantity;
      this.form.is_custom = quoteItem.is_custom;
      if (quoteItem.is_custom) {
        this.customPriceForm.customPrice = this.$store.state.displayCRC ? Number(quoteItem.custom_colones).toFixed(2) : Number(quoteItem.custom_dollars).toFixed(2);
      }
      if (this.isCustomPriceEligible(this.selectedQuoteItem)) {
        if (this.isItemCodeElegible(this.selectedQuoteItem.product.item_code) && this.selectedQuoteItem.product.cost_col > 0) {
          this.minPriceOfSelectedItemAllowed = this.quoteItemProductCost(this.selectedQuoteItem.product.cost_col)
        } else if ((this.isProductLeader || this.isAdmin) && !this.isItemCodeElegible(this.selectedQuoteItem.product.item_code)) {
          this.minPriceOfSelectedItemAllowed = this.defaultListedPrice(this.selectedQuoteItem)
        } else {
          this.minPriceOfSelectedItemAllowed = 0.01
        }
      }
      this.modalShow = true;
    },
    isCustomPriceEligible (quoteItem) {
      if (quoteItem.product && quoteItem.product.item_code) {
        if (this.isProductLeader || this.isAdmin) {
          return true
        } else {
          return this.isItemCodeElegible(quoteItem.product.item_code)
        }
      }
      return false
    },
    isItemCodeElegible (itemCode) {
      return this.itemCodesEligibles.some(substring => itemCode.toUpperCase().includes(substring)) ? true : false
    },
    quoteItemProductCost(cost_col) {
      if(!cost_col) { return }
      const costColones = cost_col
      if(this.displayCRC) {
        return costColones
      } else {
        return costColones / this.exchangeRate
      }
    },
    updateTaxCode(quoteItem, value) {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/quotes/${quoteItem.quote_id}/quote_items/${quoteItem.id}`, {
        quote_item: {
            tax_code: value,
          }
        })
        .then(response => {
          console.log(response)
          this.selectedQuoteItem = response.data
          this.$emit('sync-to-sap')
          this.$emit('item-updated', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        });
    },
    openLineText(quoteItem){
      this.selectedItemLineText = quoteItem
      this.lineTextForm.line_text = quoteItem.line_text
      this.editLineTextModalShow = true
    },
    updateLineText() {
      if (this.processing) return
      if (this.selectedItemLineText.line_text == this.lineTextForm.line_text) {
        this.editLineTextModalShow = false
        this.selectedItemLineText = null
        return
      }
      this.processing = true;
      this.$http.patch(`/api/production/quotes/${this.selectedItemLineText.quote_id}/quote_items/${this.selectedItemLineText.id}`, {
          quote_item: { line_text: this.lineTextForm.line_text }
        })
        .then(response => {
          console.log(response)
          this.$emit('sync-to-sap')
          this.$emit('item-updated', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          this.editLineTextModalShow = false
          this.selectedItemLineText = null
        });
    },
    updateItem (quoteItem) { // event from QuoteItemQuantityInput
      this.$emit('item-updated', quoteItem)
    },
    updateQuantity () {
      if (this.processing) return;
      this.processing = true;
      this.updatingQuantity = true;
      // Improve with scopedUrlString
      this.$http.patch(`/api/production/quotes/${this.selectedQuoteItem.quote_id}/quote_items/${this.selectedQuoteItem.id}`, {
          quote_item: { quantity: this.form.quantity }
        })
        .then(response => {
          console.log(response)
          this.selectedQuoteItem = response.data
          this.form.quantity = response.data.quantity
          this.$emit('item-updated', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
          // Wait 0.2 seconds until able to increment/decrement quantity again
          setTimeout(() => this.updatingQuantity = false, 200)
        });
    },
    toggleCustom (checked) {
      if (checked) {
        this.editingCustomPrice = true;
        this.$nextTick(() => this.$refs.customPriceInput.select());
      } else {
        this.editingCustomPrice = false;
        this.$http.patch(`/api/production/quotes/${this.quote.id}/quote_items/${this.selectedQuoteItem.id}`, {
            quote_item: {
              is_custom: false,
              custom_dollars: 0.0,
              custom_colones: 0.0
            }
          })
          .then(response => {
            console.log(response)
            this.selectedQuoteItem.is_custom = response.data.is_custom;
            this.selectedQuoteItem.custom_dollars = response.data.custom_dollars;
            this.selectedQuoteItem.custom_colones = response.data.custom_colones;
            this.$emit('item-updated', response.data);
            this.$emit('total-changed');
          })
          .catch(error => console.log(error))
      }
    },
    focusCustomPrice () {
      this.editingCustomPrice = true
      this.$nextTick(() => {
        this.$refs.customPriceInput.focus()
        this.$refs.customPriceInput.select()
      });
    },
    cancelCustomEditing () {
      this.editingCustomPrice = false
      if(this.displayCRC) {
        if (this.selectedQuoteItem.custom_colones == 0) { this.form.is_custom = false }
      } else {
        if (this.selectedQuoteItem.custom_dollars == 0) { this.form.is_custom = false }
      }
    },
    updateCustomPrice () {
      if (this.processing) return;
      this.processing = true;
      this.$http.patch(`/api/production/quotes/${this.quote.id}/quote_items/${this.selectedQuoteItem.id}`, {
          quote_item: {
            is_custom: true,
            custom_dollars: this.formatCustomPrice('usd'),
            custom_colones: this.formatCustomPrice('col')
          }
        })
        .then(response => {
          console.log(response, this.selectedQuoteItem)
          this.selectedQuoteItem.is_custom = response.data.is_custom
          this.selectedQuoteItem.custom_dollars = response.data.custom_dollars
          this.selectedQuoteItem.custom_colones = response.data.custom_colones
          this.$emit('item-updated', response.data)
          this.$emit('total-changed')
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.processing = false
          this.editingCustomPrice = false
        });
    },
    formatCustomPrice(currency) {
      const allowed_currencies = ['usd', 'col']
      if (!currency || !allowed_currencies.includes(currency)) { return 0 }
      const customPrice = parseFloat(this.customPriceForm.customPrice)
      if (this.displayCRC) {
        return currency == 'col' ? customPrice : customPrice / this.exchangeRate
      } else {
        return currency == 'usd' ? customPrice : customPrice * this.exchangeRate
      }
    },
    deleteItem (quoteItemId) {
      if (this.processing) return
      this.processing = true
      this.$http.delete(`/api/production/quotes/${this.quote.id}/quote_items/${quoteItemId}`)
        .then(response => {
          console.log(response)
          this.$emit('sync-to-sap')
          this.$emit('item-deleted', response.data)
          this.$emit('total-changed')
        })
        .catch(error => {
          console.log(error)
        })
        .finally(this.processing = false)
    },
    isStockEligible (quoteItem) {
      if (quoteItem.product && quoteItem.product.item_code) {
        return ['INS-', 'SERVICIO', 'SM-', 'SRVTA-', 'TRANSPORTE', 'ENCOM', 'FREIGHT', 'HORATECNICO', 'HORAYUDANT', 'PROGRAMA', 'PORTON', 'ELEMAX'].some(substring => quoteItem.product.item_code.toUpperCase().includes(substring)) ? false : true;
      } else {
        return true;
      }
    },
    productPrice(price, price_currency) {
      if (!price || !price_currency) { return 0 }
      if (this.displayCRC) {
        return price_currency === 'COL' ? price : price * this.exchangeRate
      } else {
        return price_currency === 'COL' ? price / this.exchangeRate : price
      }
    },
    listedPrice(quoteItem) {
      if (quoteItem.line_type === 'text') { return 0 }
      if (quoteItem.is_custom) {
        let priceColones = quoteItem.custom_colones
        if (this.displayCRC && quoteItem.custom_colones == 0 && quoteItem.custom_dollars > 0) {
          priceColones = quoteItem.custom_dollars * this.exchangeRate
        }
        return this.displayCRC ? priceColones : quoteItem.custom_dollars
      } else {
        if (this.priceList === 'público') {
          return this.productPrice(quoteItem.retail_price, quoteItem.price_currency)
        } else if (this.priceList === 'instalador') {
          return this.productPrice(quoteItem.installer_price, quoteItem.installer_price_currency)
        } else if (this.priceList === 'mayorista') {
          return this.productPrice(quoteItem.wholesale_price, quoteItem.wholesale_price_currency)
        }
      }
    },
    defaultListedPrice(quoteItem) {
      if (quoteItem.line_type === 'text') { return 0 }
      if (this.priceList === 'público') {
        return this.productPrice(quoteItem.retail_price, quoteItem.price_currency)
      } else if (this.priceList === 'instalador') {
        return this.productPrice(quoteItem.installer_price, quoteItem.installer_price_currency)
      } else if (this.priceList === 'mayorista') {
        return this.productPrice(quoteItem.wholesale_price, quoteItem.wholesale_price_currency)
      }
    },
    itemProfitability(item) {
      const costCol = item.product.cost_col
      const itemPrice = this.listedPrice(item)
      const rate = this.$store.state.settings.usd_crc_rate //TODO: Go over this if is this.exchangeRate
      return this.displayCRC ?  costCol > itemPrice : costCol / rate > itemPrice
    },
    clearSelectedItem () {
      this.form.quantity = null
      this.customPriceForm.customPrice = 0
      this.selectedQuoteItem = null
      this.editingCustomPrice = false
    },
    updateItemStock (stock) {
      // TODO: Double check this works properly
      this.$emit('item-updated', this.selectedQuoteItem)
    },
    decrement () {
      this.updatingQuantity = true
      this.form.quantity--
      this.updateQuantity()
    },
    increment () {
      this.updatingQuantity = true
      this.form.quantity++
      this.updateQuantity()
    },
    addQuoteItem (quoteItem) {
      this.$emit('quote-item-added', quoteItem)
      this.$emit('total-changed')
    }
  }
}
</script>

<style scoped>
.td-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
