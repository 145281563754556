import { normalize, schema } from 'normalizr';
import Vue from 'vue'

const state = {
  // Selected Purchase Delivery
  purchaseDeliverySelected: null,
  deliveryItemData: {},
  deliveryItemList: [],
  // Searched Purchase order: come from SAP
  purchaseOrderSearched: null,
  searchOrigin: null,
  // Selected Purchase order: come from xyz
  purchaseOrderSelected: null,
  orderItemData: {},
  orderItemList: [],
};

const getters = {
  // user permissions
  // can do anything
  isPurchaseDeliveryAdmin (state, _, rootState) {
    const users = {
      3194: 'Fernanda Corrales',
      3197: 'Jefry Vargas Medina',
      123: 'Raquel Araya',
      212: 'Ronald Segura',
      3193: 'Adriana Robles',
      28: 'Guillermo Ramirez',
    }
    const allowedUserIds = Object.keys(users).map(Number)
    return allowedUserIds.includes(rootState.currentUser.id)
  },
  // can only search and register
  isPurchaseDeliverySupervisor (state, _, rootState) {
    const users = {
      196: 'Gabriel Quiros',
      74: 'Alexis Arias',
      2477: 'Carlos Jimenez',
      136: 'Greivin Brenes',
      493: 'Aurelio Montero'
    }
    const allowedUserIds = Object.keys(users).map(Number)
    return allowedUserIds.includes(rootState.currentUser.id)
  },
  // Selected Purchase Delivery
  purchaseDeliverySelected (state) {
    return state.purchaseDeliverySelected
  },
  purchaseDeliveryItems (state) {
    if (state.deliveryItemList.length == 0) return []
    return state.deliveryItemList.map(id => state.deliveryItemData[id])
  },
  notCheckedItems (state, getters) {
    if (state.deliveryItemList.length == 0) return []
    return getters.purchaseDeliveryItems.filter(item => !item.is_checked)
  },
  // TODO: Refactor name methods!
  canBeFinished (state, getters) {
    let result = true
    if (state.deliveryItemList.length == 0) { result = false }
    if (state.purchaseDeliverySelected.is_executed) { result = false }
    const SomeItemUnchecked = getters.purchaseDeliveryItems.some(item => !item.is_checked)
    if (SomeItemUnchecked) { result = false}
    const invalidSapItems = getters.purchaseDeliveryItems.filter(item => {
      return item.origin == 'SAP' && item.quantity !== item.sap_quantity && !item.notes
    })
    if (invalidSapItems.length > 0) { result = false }
    if (state.deliveryItemList.length ==  getters.purchaseDeliveryItems.filter(item => item.quantity == 0).length) { result = false}
    return result
  },
  canCreateEmOnSap (state, getters) {
    let result = true
    if (state.deliveryItemList.length == 0) { result = false }
    if (getters.purchaseDeliverySelected.sap_doc_no) { result = false }

    const invalidItems = getters.purchaseDeliveryItems.filter(item => {
      return item.is_active && item.price == 0
    })
    if (invalidItems.length > 0) { result = false }

    if (state.deliveryItemList.length == getters.purchaseDeliveryItems.filter(item => !item.is_active).length) {
      result = false
    }
    return result
  },
  searchItemByItemCode: (state, getters) => (itemCode) => {
    if (state.deliveryItemList.length == 0) return
    return getters.purchaseDeliveryItems.find(item => {
      let searchItem = item.sap_item_code.toLowerCase()
      // remove accents from a string
      searchItem = searchItem.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      // remove whitespaces from a string
      searchItem = searchItem.replace(/ /g, '')
      return searchItem == itemCode
    })
  },
  searchItemByBarCode: (state, getters) => (barCode) => {
    if (state.deliveryItemList.length == 0) return
    return getters.purchaseDeliveryItems.find(item => {
      return item.item_barcode == barCode
    })
  },
  // Searched Purchase order
  purchaseOrderSearched (state) {
    return state.purchaseOrderSearched
  },
  searchOrigin (state) {
    return state.searchOrigin
  },
  purchaseOrderSearchedItems (state) {
    if (!state.purchaseOrderSearched) return []
    return state.purchaseOrderSearched.purchase_order_items
  },
  // Selected Purchase order
  purchaseOrderSelected (state) {
    return state.purchaseOrderSelected
  },
  purchaseOrderItems (state) {
    if (state.orderItemList.length == 0) return []
    return state.orderItemList.map(id => state.orderItemData[id])
  },
};

const mutations = {
  // Selected Purchase Delivery
  setSelectedPurchaseDelivery (state, payload) {
    if (!payload) {
      state.purchaseDeliverySelected = null
      state.deliveryItemData = {}
      state.deliveryItemList = []
    } else {
      state.purchaseDeliverySelected = payload
      const dataNormalized = normalize(
        { items: payload.purchase_delivery_items },
        { items: [ new schema.Entity('items') ] }
      )
      if (dataNormalized.entities.hasOwnProperty('items')) {
        state.deliveryItemData = dataNormalized.entities.items
      }
      state.deliveryItemList = dataNormalized.result.items
    }
  },
  setPurchaseDeliveryData (state, payload) {
    state.purchaseDeliverySelected.is_executed = payload.is_executed
    state.purchaseDeliverySelected.usd_crc_rate = payload.usd_crc_rate
    state.purchaseDeliverySelected.eur_crc_rate = payload.eur_crc_rate
    state.purchaseDeliverySelected.can_crc_rate = payload.can_crc_rate
    state.purchaseDeliverySelected.register_notes = payload.register_notes
    state.purchaseDeliverySelected.doc_date = payload.doc_date
    state.purchaseDeliverySelected.invoice_number = payload.invoice_number
    state.purchaseDeliverySelected.notes = payload.notes
    state.purchaseDeliverySelected.discount = payload.discount
    state.purchaseDeliverySelected.doc_total = payload.doc_total
    state.purchaseDeliverySelected.additional_expense = payload.additional_expense
    state.purchaseDeliverySelected.sap_is_synced = payload.sap_is_synced
    state.purchaseDeliverySelected.sap_doc_entry = payload.sap_doc_entry
    state.purchaseDeliverySelected.sap_doc_no = payload.sap_doc_no
    state.purchaseDeliverySelected.sap_errors = payload.sap_errors
  },
  updatedSelectedItem(state, payload) {
    let item = state.deliveryItemData[payload.id]
    const updatedItem = {
        ...item,
        is_active: payload.is_active,
        is_checked: payload.is_checked,
        notes: payload.notes,
        price: payload.price,
        quantity: payload.quantity,
        sap_quantity: payload.sap_quantity,
        tax_code: payload.tax_code,
        warehouse_code: payload.warehouse_code,
        position: payload.position,
        usd_price: payload.usd_price
    }
    Vue.set(state.deliveryItemData, payload.id, updatedItem)
  },
  addItemToPurchaseDelivery (state, item) {
    state.deliveryItemList.push(item.id)
    Vue.set(state.deliveryItemData, item.id, item);
  },
  // Searched Purchase Order
  setPurchaseOrderSearched (state, payload) {
    state.purchaseOrderSearched = payload
  },
  updatePurchaseDeliveriesCount(state, payload) {
    state.purchaseOrderSelected.purchase_deliveries.push(payload)
  },
  setSearchOrigin (state, payload) {
    state.searchOrigin = payload
  },
  // Selected Purchase Order
  setPurchaseOrderSelected (state, payload) {
    if (!payload) {
      state.purchaseOrderSelected = null
      state.orderItemData = {}
      state.orderItemList = []
    } else {
      state.purchaseOrderSelected = payload
      const dataNormalized = normalize(
        { items: payload.purchase_order_items },
        { items: [ new schema.Entity('items') ] }
      )
      if (dataNormalized.entities.hasOwnProperty('items')) {
        state.orderItemData = dataNormalized.entities.items
      }
      state.orderItemList = dataNormalized.result.items
    }
  },
  resetPurchaseOrderState (state) {
    // Searched Purchase order: come from SAP
    state.purchaseOrderSearched = null
    state.searchOrigin = null
    // Selected Purchase order: come from xyz
    state.purchaseOrderSelected = null
    state.orderItemData = {}
    state.orderItemList = []
  }
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations
};

