<template>
  <b-container fluid>
    <b-row align-h="center">
      <b-col class="p-0">
        <h2 class="pb-2">CONSULTAR OC</h2>
        <b-row class="pl-3">
          <b-form-group class="m-0" style="width: 21rem">
            <b-input-group size="sm">
              <b-input
                v-model="searchTerm"
                type="number"
                autofocus
                trim
                class="no-spin"
                placeholder="Número de documento OC"
                :disabled="isLoading"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="outline-secondary" size="sm" :disabled="isLoading" @click="search">
                  <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" pulse fixed-width />
                  <font-awesome-icon v-else :icon="['fas', 'search']" fixed-width />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group class="ml-3">
            <b-button size="sm" @click="resetSearch" variant="secondary">
              <font-awesome-icon :icon="['far', 'undo-alt']" fixed-width />
            </b-button>
          </b-form-group>
        </b-row>
      </b-col>
    </b-row>
    <hr >
    <b-row>
      <b-col class="p-0 pt-3">
        <b-container fluid v-if="!order || isLoading" class="empty-search">
          <div>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" pulse class="text-info modal-icon"/>
            <font-awesome-icon v-else-if="error" :icon="['fas', 'exclamation-triangle']" class="text-warning modal-icon"/>
            <font-awesome-icon v-else :icon="['far', 'info-circle']" class="text-dark modal-icon" />
          </div>
          <div>
            <p v-if="isLoading" class="fs-20 pl-3 text-info">Cargando...</p>
            <p v-else-if="error" class="fs-20">{{error}}</p>
            <p v-else class="fs-20">Realice la búsqueda de la OC</p>
          </div>
        </b-container>
        <PurchaseDeliveryContent
          v-else
          :purchase-delivery="order"
          parent-component="search"
          :iconHeader="['fas', 'search']"
        >
          <template v-slot:table-caption>
            <!-- <span v-if="purchaseOrder" class="text-muted">
              <font-awesome-icon :icon="['far', 'info-circle']" size="sm"/>
              Presione el botón de {{ searchOrigin =='XYZ' ? 'continuar' : 'registrar' }} al final de la página para seguir con el proceso
            </span> -->
            <b-row v-if="purchaseOrderSelected && !isLoading">
              <b-col>
                <div
                  style="cursor: pointer"
                  :class="showPurchaseDeliveries ? null : 'collapsed'"
                  :aria-expanded="showPurchaseDeliveries ? 'true' : 'false'"
                  aria-controls="collapse-1"
                  @click="openPurchaseDeliveries()"
                >
                  <h5 class="p-0">
                    <span>Ver o crear EM de la OC</span>
                    <sup v-if="showPurchaseDeliveries">({{purchaseDeliveries.length}})</sup>
                    <font-awesome-icon v-if="showPurchaseDeliveries" :icon="['far', 'chevron-down']" size="lg" fixed-width />
                    <font-awesome-icon v-else :icon="['far', 'chevron-right']" size="sm" fixed-width />
                  </h5>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="showPurchaseDeliveries">
              <b-col>
                <div class="text-center">
                  <ul v-if="loadingPurchaseDeliveries" :class="'ul-margin'" class="fs-16 mb-0m pt-3">
                    <li class="pb-2">
                      <table class="table table-borderless table-sm brown-color m-0">
                        <tr style="vertical-align: middle">
                          <td class="p-0 text-primary text-left pl-2" style="width: 8rem">
                            <b-spinner small type="grow"></b-spinner>
                            <span class="pl-2">Cargando...</span>
                          </td>
                        </tr>
                      </table>
                    </li>
                  </ul>
                  <ul v-if="!loadingPurchaseDeliveries && purchaseDeliveries.length == 0" :class="'ul-margin'" class="fs-16 mb-0 pt-3">
                    <li class="pb-2">
                      <table class="table table-borderless table-sm brown-color m-0">
                        <tr style="vertical-align: middle">
                          <td class="p-0 text-danger text-left" style="width: 8rem">
                            <span class="pl-2">Sin EM registradas</span>
                          </td>
                        </tr>
                      </table>
                    </li>
                  </ul>
                  <ul v-if="!loadingPurchaseDeliveries && purchaseDeliveries.length > 0" :class="'ul-margin'" class="fs-16 mb-0 pt-3">
                    <li v-for="purchaseDelivery in purchaseDeliveries" :key="purchaseDelivery.id" class="pb-2">
                      <table class="table table-borderless table-sm brown-color m-0">
                        <tr style="vertical-align: middle">
                          <td class="p-0" style="width: 12rem">
                            <span class="pl-2"># {{ purchaseDelivery.purchase_internal_id }}</span>
                          </td>
                          <td class="p-0 text-center" style="width: 20px;">|</td>
                          <td class="p-0 text-center" style="width: 8rem">
                            <span v-if="purchaseDelivery.sap_doc_no"> SAP # {{purchaseDelivery.sap_doc_no}}</span>
                            <span v-else class="text-muted"> Sin # doc SAP</span>
                          </td>
                          <td class="p-0 text-center" style="width: 10px;">|</td>
                          <td class="p-0 text-center" style="width: 8rem">
                            <span v-if="purchaseDelivery.sap_doc_no"><b-badge variant="success">Finalizada</b-badge></span>
                            <span v-else-if="purchaseDelivery.is_executed"><b-badge variant="warning">En validación</b-badge></span>
                            <span v-else ><b-badge variant="danger">En registro</b-badge></span>
                          </td>
                          <td class="p-0 text-center" style="width: 20px;">|</td>
                          <td class="p-0 text-left">
                            <b-button class="py-0" pill variant="outline-primary" size="sm" @click="goToPurchaseDelivery(purchaseDelivery)">Ir a Em</b-button>
                          </td>
                        </tr>
                      </table>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
            <b-row align-h="end" v-if="showPurchaseDeliveries && !loadingPurchaseDeliveries">
              <b-col>
                <Button v-if="purchaseDeliveries.length > 0" style="margin-left: 7.2%;" :disabled="!canCreateEmOnApp" variant="orange" @click="openConfirmationModal('createAnotherDelivery')">
                  <span>Registrar otra EM</span>
                </Button>
                <Button v-else style="margin-left: 6%;" :disabled="!canCreateEmOnApp" variant="orange" @click="openConfirmationModal('createDelivery')">
                  <span>Iniciar conteo</span>
                </Button>
              </b-col>
            </b-row>
            <hr>
          </template>
          <template v-slot:table-footer>
            <b-row align-h="end" v-if="purchaseOrder">
              <b-col class="text-right">
                <Button v-if="searchOrigin == 'SAP'" size="lg" variant="green" @click="openConfirmationModal('createOrder')">Registrar</Button>
                <Button v-else-if="searchOrigin == 'XYZ' && purchaseDeliveriesCount == 0" size="lg" variant="green" @click="openConfirmationModal('createDelivery')">Iniciar conteo</Button>
                <!-- <Button v-else-if="searchOrigin == 'XYZ' && purchaseDeliveriesCount > 0" variant="green" @click="openConfirmationModal('createAnotherDelivery')">Crear otra EM</Button> -->
              </b-col>
            </b-row>
          </template>
        </PurchaseDeliveryContent>
      </b-col>
    </b-row>

    <!-- Alert -->
    <ToastAlert :show="alertShow" :variant="alertVariant" @close="alertShow = false">
      {{ alertMessage }}
    </ToastAlert>

    <!-- confirmation modal -->
    <b-modal
      v-model="confirmationOrderModal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
    <div v-if="purchaseOrder">
      <b-row align-h="center" >
        <font-awesome-icon :icon="['far', 'info-circle']"  class="text-info modal-icon" />
      </b-row>
      <b-row align-h="center" class="text-center">
        <b-col>
          <b v-if="confirmationAction == 'createOrder'" class="fs-20">¿Desea registrar la OC # {{purchaseOrder.sap_doc_no}} en XYZ?</b>
          <b v-if="confirmationAction == 'createDelivery' || confirmationAction == 'createAnotherDelivery'" class="fs-20">¿Desea iniciar el conteo de la OC # {{purchaseOrder.sap_doc_no}}?</b>
        </b-col>
      </b-row>
      <b-row align-h="center" class="pt-2 text-secondary">
        <!-- <small v-if="confirmationAction == 'createOrder'">NOTA: Se creará la OC en XYZ</small> -->
        <small v-if="confirmationAction == 'createDelivery'">NOTA: Se creará una PRE-EM en XYZ para poder realizar el conteo</small>
        <small v-if="confirmationAction == 'createAnotherDelivery'">NOTA: Se creará otra PRE-EM en XYZ para poder realizar el conteo faltante</small>
      </b-row>
      <b-row v-if="processing" align-h="center">
        <div class="text-center text-info p-2 fs-18">
          <b-spinner type="grow"></b-spinner>
          <span class="pl-2 pb-2">Creando...</span>
        </div>
      </b-row >
      <b-row v-else align-h="around" class="my-4">
        <Button variant="red" @click="confirmationOrderModal = false;">Cancelar</Button>
        <Button v-if="confirmationAction == 'createOrder'" variant="green" @click="createPurchaseOrder()">Continuar</Button>
        <Button v-if="confirmationAction == 'createDelivery'" variant="green" @click="createPurchaseDelivery()">Continuar</Button>
        <Button v-if="confirmationAction == 'createAnotherDelivery'" variant="green" @click="createPurchaseDelivery()">Continuar</Button>
      </b-row>
    </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import PurchaseDeliveryContent from './PurchaseDeliveryContent'
import ToastAlert from '../../shared/ToastAlert.vue';
import Button from '../../shared/Button'
export default {
  name: 'PurchaseDeliverySearch',
  components: { PurchaseDeliveryContent, Button, ToastAlert },
  data () {
    return {
      searchTerm: '',
      isLoading: false,
      error: null,
      processing: false,
      confirmationOrderModal: false,
      confirmationAction: '',
      showPurchaseDeliveries: false,
      loadingPurchaseDeliveries: false,
      purchaseDeliveries: [],
      alertShow: false,
      alertVariant: null,
      alertMessage: '',
    }
  },

  computed : {
    ...mapGetters({
      purchaseOrder:'purchaseOrderSearched',
      purchaseOrderSelected:'purchaseOrderSelected',
      searchOrigin: 'searchOrigin'
    }),
    purchaseDeliveriesCount () {
      if (this.searchOrigin == 'XYZ' && this.purchaseOrderSelected && this.purchaseOrderSelected.purchase_deliveries) {
        return this.purchaseOrderSelected.purchase_deliveries.length
      }
      return 0
    },
    order () {
      if (!this.purchaseOrder && !this.purchaseOrderSelected ) { return null }
      if (this.purchaseOrderSelected) { return this.purchaseOrderSelected }
      return this.purchaseOrder
    },
    canCreateEmOnApp () {
      let result = true
      // check if the status of each purchase delivery is synced to SAP
      if (this.purchaseDeliveries.length > 0 && this.purchaseDeliveries.some(el => !el.sap_is_synced)) { result = false }
      // check if at least one item on the purchase order has a remaining_quantity > 0
      if (this.purchaseOrderSelected && this.purchaseOrderSelected.purchase_order_items.length > 0) {
        let { purchase_order_items: items } = this.purchaseOrderSelected
        const validItems = items.filter(({origin, remaining_quantity}) => origin == 'SAP' && remaining_quantity > 0)
        if (validItems.length == 0) { result = false}
      } else { result = false }

      return result
    }
  },
  methods: {
    search () {
      if (this.processing || !this.searchTerm){ return }
      this.processing = true
      this.isLoading = true
      this.$http.get('/api/production/purchase_orders/fetch_sap_purchase_order', {
          params: {
            q: this.searchTerm
          }
        })
        .then(response => {
          // console.log(response)
          this.$store.commit('setSearchOrigin', response.data.origin)
          if (response.data.origin == 'XYZ') {
            this.$store.commit('setPurchaseOrderSelected', response.data.order)
          } else {
            this.$store.commit('setPurchaseOrderSelected', null)
          }
          this.$store.commit('setPurchaseOrderSearched', response.data.order)
          this.$store.commit('setSelectedPurchaseDelivery', null)
        })
        .catch(e => {
          console.log(e)
          this.$store.commit('setSearchOrigin', null)
          this.$store.commit('setPurchaseOrderSearched', null)
          this.$store.commit('setPurchaseOrderSelected', null)
          this.error = e.response.data.error
        })
        .finally(() => {
          this.showPurchaseDeliveries =  false
          this.purchase_deliveries = []
          this.isLoading = false
          this.processing = false
        })
    },
    resetSearch () {
      this.error = null
      this.searchTerm = ''
      this.$store.commit('setPurchaseOrderSearched', null)
      this.$store.commit('setPurchaseOrderSelected', null)
    },
    openConfirmationModal (action) {
      this.confirmationAction = action
      this.confirmationOrderModal = true
    },
    createPurchaseOrder () {
      if (this.processing || !this.purchaseOrder || this.searchOrigin != 'SAP'){ return }
      this.processing = true
      const data = this.formatPurchaseOrderData()
      this.$http.post('/api/production/purchase_orders', data)
        .then(response => {
          console.log(response)
          this.$store.commit('setPurchaseOrderSelected', response.data)
          this.$store.commit('setPurchaseOrderSearched', response.data)
          this.$store.commit('setSearchOrigin', 'XYZ')
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'Error: Por favor intente nuevamente'
          this.alertShow = true
        })
        .finally(() => {
          setTimeout(() => {
            this.processing = false
            this.confirmationOrderModal = false
          }, 3000);
        })
    },
    createPurchaseDelivery () {
      if (this.processing || !this.purchaseOrderSelected || this.searchOrigin != 'XYZ'){ return }
      this.processing = true
      const data = this.formatPurchaseDeliveryData()
      this.$http.post(`/api/production/purchase_orders/${this.purchaseOrderSelected.id}/purchase_deliveries`, data)
        .then(response => {
          console.log(response)
          this.$store.commit('updatePurchaseDeliveriesCount', { id: response.data.id, is_executed: response.data.is_executed })
          this.$store.commit('setSelectedPurchaseDelivery', response.data)
          this.$store.commit('setSearchOrigin', 'XYZ')
          this.$router.push({name: 'PurchaseDeliveryRegister'})
        })
        .catch(e => {
          console.log(e)
          this.alertVariant = 'danger'
          this.alertMessage = 'Error: Por favor intente nuevamente'
          this.alertShow = true
        })
        .finally(() => {
          setTimeout(() => {
            this.processing = false
            this.confirmationOrderModal = false
          }, 3000);
        })
    },
    formatPurchaseOrderData () {
      const {
        client_id, sap_doc_no, sap_doc_entry, doc_currency, usd_crc_rate,
        doc_total ,doc_type, discount, purchase_order_items
      } = this.purchaseOrder
      return {
        purchase_order: {
          client_id,
          sap_doc_no,
          sap_doc_entry,
          doc_currency,
          usd_crc_rate,
          doc_type,
          discount,
          doc_total
        },
        purchase_order_items,
      }
    },
    formatPurchaseDeliveryData () {
      const {
        id, client_id, doc_currency,
        doc_total ,doc_type, discount, purchase_order_items
      } = this.purchaseOrderSelected
      const items = purchase_order_items.filter(item => item.remaining_quantity > 0).map((
          { origin, remaining_quantity, sap_item_code, item_description,
            item_barcode, item_location, sap_line_num, price, product_id, tax_code,
            usd_price, item_currency, sap_vendor_code, item_rate, warehouse_code
          }
        ) => ({
        origin,
        quantity: 0,
        sap_quantity: remaining_quantity,
        sap_item_code,
        item_description,
        item_barcode,
        item_location,
        sap_line_num,
        price,
        product_id,
        tax_code,
        usd_price,
        item_rate,
        item_currency,
        sap_vendor_code,
        warehouse_code
      }))
      return {
        purchase_delivery: {
          client_id,
          doc_currency,
          doc_type,
          discount,
          doc_total,
          purchase_order_id: id
        },
        purchase_delivery_items: items,
      }
    },
    openPurchaseDeliveries () {
      if (this.showPurchaseDeliveries) {
        this.showPurchaseDeliveries = false
      } else {
        this.showPurchaseDeliveries = true
        this.fetchPurchaseDeliveries()
      }
    },
    fetchPurchaseDeliveries() {
      if (this.loadingPurchaseDeliveries || !this.purchaseOrderSelected || this.searchOrigin != 'XYZ'){ return }
      this.loadingPurchaseDeliveries = true
      this.$http.get(`/api/production/purchase_orders/${this.purchaseOrderSelected.id}/purchase_deliveries`)
        .then(response => {
          console.log(response)
          this.purchaseDeliveries = response.data
          this.loadingPurchaseDeliveries = false
        })
        .catch(e => {
          console.log(e)
          this.purchaseDeliveries = []
          this.loadingPurchaseDeliveries = false
        })
    },
    goToPurchaseDelivery (purchaseDelivery) {
      this.$store.commit('setSelectedPurchaseDelivery', purchaseDelivery)
      if (purchaseDelivery.is_executed) {
        this.$router.push({name: 'PurchaseDeliveryValidation'})
      } else {
        this.$router.push({name: 'PurchaseDeliveryRegister'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-search {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  opacity: 0.7;
}

.open-link {
  &:hover {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
}

/*Tree lines*/
.ul-margin {
  margin-left: 4%;
}

ul, li {
  position: relative;
}
ul {
  list-style: none;
  padding-left: 35px;
}

li::before, li::after {
  content: "";
  position: absolute;
  left: -12px;
}
li::before {
  border-top: 1px solid rgb(121, 100, 101);
  top: 9px;
  width: 11px;
  height: 0;
}
li::after {
  border-left: 1px solid rgb(121, 100, 101);
  top: -10px;
  width: 0px;
  height: 100%;
}
ul > li:last-child::after {
  height: 20px;
}
</style>
